@import '../../../sass/global.scss';

.player {
	.player__grid {
		grid-column: 1 / -1;
		display: grid;
		grid-template-columns: 1fr;

		@media #{$mq-l} {
			grid-template-columns: 3fr 2fr;
			min-height: calc(100vh - #{$header-height-l});
		}
	}

	.player__video {
		position: relative;
	}

	.player__iframe {
		display: none;
		border: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;

		&.player__iframe--active {
			display: block;
		}
	}

	.player__details {
		background-color: $c-pebble;
		position: relative;
		padding: $gutter-s;
		overflow: hidden;
		font-size: 1.4rem;
		line-height: 2.4rem;
		overflow: hidden;

		@media #{$mq-m} {
			padding: $gutter-m;
		}
	}

	.player__bg {
		display: none;

		@media #{$mq-l} {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			opacity: 0.2;
		}
	}

	.player__copy {
		position: relative;
	}

	.player__title {
		margin: 0;
		font-weight: 700;
		color: $c-white;
	}

	.player__about {
		.about__title {
			color: $c-red-dark;
		}

		.about__intro {
			font-weight: 700;
			font-size: 1.8rem;
			line-height: 2.8rem;
		}

		.about__footer {
			margin: $gutter-s 0 0 0;
		}

		.about__btns {
			margin: $gutter-s 0 0 0;
		}
	}

	.player__genres {
		display: flex;
		flex-wrap: wrap;
		margin: $gutter-s 0 -10px 0;

		li {
			list-style: none;
			margin: 0 $gutter-s-half $gutter-s-half 0;
			font-weight: 700;
			font-size: 1.2rem;
			line-height: 1.2rem;
		}
	}

	.player__meta {
		display: flex;
		flex-wrap: wrap;
		margin: 0;

		li {
			display: flex;
			align-items: center;
			margin: 0 $gutter-s-half 0 0;
			list-style: none;
			font-weight: 700;

			svg {
				margin: 0 10px 0 0;
				height: 14px;
				max-width: 14px;
			}

			path {
				fill: rgba($c-white, 0.6);
			}

			&:last-child {
				margin: 0;
			}
		}
	}

	.player__btns {
		margin: 0 0 $gutter-s 0;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.btns__btn {
			margin: 0 $gutter-s 0 0;

			&:last-child {
				margin: 0;
			}
		}
	}

	.player__plot {
		margin: $gutter-s 0 0 0;

		@media #{$mq-l} {
			max-height: 120px;
			overflow-y: auto;
		}
	}

	.player__controls {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: $gutter-s 0 0 0;

		.controls__list {
			display: flex;
			align-items: center;
			margin: 0;
			padding: 0;
		}

		li {
			list-style: none;
			flex: 0 0 auto;
			margin: 0 $gutter-s 0 0;

			&:last-child {
				margin: 0;
			}
		}

		button {
			width: auto;
		}
	}

	.player__progress {
		display: flex;
		justify-content: space-between;
		margin: $gutter-s 0 0 0;

		.progress__progress {
			background-color: $c-white;
			border: none;
			border-radius: 80px;
			appearance: none;
			width: 100%;
			height: 10px;
			overflow: hidden;
		}

		.progress__progress::-moz-progress-bar,
		.progress__progress::-webkit-progress-bar {
			background-color: $c-red;
			border-radius: 80px 0 0 80px;
		}

		.progress__form {
			width: 100%;
		}

		.progress__val {
			margin: 0 0 0 $gutter-s;
			font-weight: 700;
		}
	}

	.player__download {
		margin: $gutter-s 0 0 0;
	}

	.player__credits {
		transition: all 200ms;
		transform: translateX(100%);
		background-color: $c-black;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		padding: 0 0 $gutter-s 0;

		@media #{$mq-m} {
			padding: 0 0 $gutter-m 0;
		}

		@media #{$mq-l} {
			position: absolute;
			height: 100%;
		}

		.credits__mask {
			position: relative;
			padding: $gutter-s;
			width: 100%;
			height: 100%;
			overflow-y: auto;

			@media #{$mq-m} {
				padding: $gutter-m;
			}
		}

		.credits__list {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: $gutter-s;
			margin: 0;
			padding: 0;
			color: $c-white;

			.list__item {
				list-style: none;
				display: flex;
				margin: 0;

				img {
					border-radius: 50%;
					margin: 0 $gutter-s-half 0 0;
					flex: 0 0 auto;
					width: 50px;
					height: 50px;
					object-fit: cover;
				}

				.item__title {
					margin: 0;
					color: $c-white;

					&.item__title--bold {
						font-weight: 700;
					}
				}
			}
		}

		.credits__close {
			position: absolute;
			top: $gutter-s;
			right: $gutter-s;
		}

		&.player__credits--active {
			transform: none;
		}
	}

	&.player--active {
		.player__details {
			background-color: $c-black;
			color: $c-white;
		}
	}
}
