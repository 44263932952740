@import '../../../sass/global.scss';

.genres {
	padding: $gutter-s;

	@media #{$mq-m} {
		padding: $gutter-m;
	}

	.genreslist__list {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: $gutter-s;
		margin: 0;
		padding: 0;

		@media #{$mq-m} {
			grid-template-columns: 1fr 1fr 1fr;
		}

		@media #{$mq-l} {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}

		.list__item {
			list-style: none;

			.item__btn {
				transition: all 200ms;
				background-color: $c-black;
				position: relative;
				border: none;
				border-radius: 6px;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				width: 100%;
				height: 340px;
				padding: $gutter-s;
				text-align: left;
				color: $c-white;
				overflow: hidden;
				cursor: pointer;

				&:hover {
					transform: scale(1.05);

					.item__image {
						img {
							opacity: 1;
						}
					}
				}
			}

			.item__image {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				img {
					transition: all 200ms;
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0.8;
					object-fit: cover;
				}

				&:after {
					background: linear-gradient(to top, $c-black, transparent);
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 80%;
					content: '';
				}
			}

			.item__title {
				position: relative;
				text-shadow: 0px 0px 10px rgba($c-black, 0.8);
				margin: 0;
			}
		}
	}
}
