label {
  display: block;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.4rem;
  margin: 0 0 $gutter-s 0;
	cursor: pointer;
}

input:not([type="checkbox"]),
select {
  appearance: none;
}

input, select {
  background-color: $c-white;
	box-shadow: 0px 0px 30px rgba($c-black, 0.1);
  border: none;
  border-radius: 6px;
  padding: 15px;
	font-family: $f-pt-sans;
	font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.4rem;
  width: 100%;
	color: $c-boulder;
}

select {
	cursor: pointer;
}

input {

	&[type="checkbox"] {
		margin: 0;
		width: auto;
		height: auto;
	}

	&[type="file"] {
		cursor: pointer;
	}
}
