@import '../../../sass/global.scss';

.header {
	background: $c-red;
	display: flex;
	align-items: center;
	padding: $gutter-s;

	@media #{$mq-l} {
		padding: 0 $gutter-m;
		height: $header-height-l;
	}

	.header__content {
		display: grid;
		grid-template-columns: 1fr 2fr;
		grid-gap: $gutter-s;
		width: 100%;

		@media #{$mq-m} {
			grid-template-columns: 1fr 1fr;
		}

		@media #{$mq-l} {
			grid-template-columns: 1fr 2fr;
		}
	}

	.header__name {
		display: flex;
		align-items: center;
		font-weight: 700;
		text-shadow: 1px 1px 10px rgba($c-black, 0.1);
		color: $c-white;

		a {
			color: $c-white;
			text-decoration: none;
		}
	}

	.header__title {
		display: flex;
		align-items: center;
		font-weight: 400;
		font-size: 3rem;
		line-height: 3rem;
		margin: 0;

		@media #{$mq-m} {
			margin: 0 $gutter-s-half 0 0;
		}

		span {
			font-weight: 700;
		}
	}

	.header__tagline {
		display: none;

		@media #{$mq-m} {
			//display: inline-flex;
		}
	}

	.header__controls {
		display: none;

		.controls__nav {
			display: flex;
			justify-content: flex-end;

			.nav__list {
				display: flex;
				margin: 0;
				padding: 0;

				li {
					margin: 0 $gutter-s 0 0;
					list-style: none;

					&:last-child {
						margin: 0;
					}
				}
			}
		}

		.controls__filters {
			transition: all 200ms;
			transform: translateX(100%);
			background-color: map-deep-get($material, 'red', '600');
			padding: $gutter-s;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 100;
			color: $c-white;

			@media #{$mq-m} {
				padding: $gutter-m;
			}

			@media #{$mq-l} {
				transform: translateX(0);
				background-color: transparent;
				position: relative;
				top: auto;
				left: auto;
				width: auto;
				height: auto;
				padding: 0;
				margin: 0 0 0 $gutter-s;
			}

			.filters__title {
				@media #{$mq-l} {
					display: none;
				}
			}

			.filters__list {
				display: grid;
				grid-template-columns: 1fr;
				grid-gap: $gutter-s;
				margin: 0;

				@media #{$mq-m} {
					grid-template-columns: 2fr 1fr;
				}

				li {
					list-style: none;
					margin: 0;
				}
			}
		}

		.controls__browse {
			margin: 0 0 0 $gutter-s;

			.input {

				.input__text {
					display: none;

					@media #{$mq-m} {
						display: inline-flex;
					}
				}

				.input__btn {
					border-radius: 6px;

					@media #{$mq-m} {
						border-radius: 0 6px 6px 0;
					}
				}
			}
		}

		a,
		input,
		select,
		button {
			padding: $gutter-s-half;
			width: 100%;
			height: 40px;
		}
	}

	&.header--media {

		.header__controls {
			display: flex;
			justify-content: flex-end;
		}
	}
}
