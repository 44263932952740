@import './sass/global.scss';
@import './sass/core/layout.scss';
@import './sass/core/typography.scss';
@import './sass/core/forms.scss';

main {
	overflow: hidden;
}

.fade-enter {
  opacity: 0;
  z-index: 1;

	&.fade-enter-active {
	  opacity: 1;
	  transition: opacity 300ms ease;
	}
}

.slide-in-enter {
	transform: translateY(100%);
	opacity: 0;

	&.slide-in-enter-active {
		transition: all 300ms ease;
		transform: none;
		opacity: 1;
	}
}
