@import '../../../sass/global.scss';

.footer {
	background: $c-mine;
	padding: $gutter-s;
	font-size: 1.2rem;
	line-height: 2.2rem;
	color: $c-white;

	@media #{$mq-l} {
		padding: $gutter-s $gutter-m;
	}

	a {
		color: $c-stone;
		text-decoration: none;
		font-weight: 600;

		&:hover {
			color: $c-white;
		}
	}

	.footer__content {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: $gutter-s;

		@media #{$mq-m} {
			grid-template-columns: 1fr 1fr;
		}

		@media #{$mq-l} {
			grid-template-columns: 1fr 2fr;
		}
	}

	.footer__name {
		display: flex;
		align-items: center;
		font-weight: 700;
		color: $c-white;

		a {
			color: $c-white;
			text-decoration: none;
		}
	}

	.footer__title {
		display: flex;
		align-items: center;
		font-weight: 400;
		font-size: 2rem;
		line-height: 2rem;
		margin: 0;

		@media #{$mq-m} {
			margin: 0 $gutter-s-half 0 0;
		}

		span {
			font-weight: 700;
		}
	}

	.footer__credit {

		@media #{$mq-m} {
			text-align: right;
		}
	}

	&.footer--home {
		background-color: $c-red;
		color: $c-white;

		a {
			color: rgba($c-black, 0.6);
		}

		.footer__name {
			color: $c-white;

			a {
				color: $c-white;
			}
		}
	}
}
