@import '../../../sass/global.scss';

.hero {
	background-color: $c-red;
	min-height: calc(100vh - #{$header-height-l});
	display: flex;
	align-items: center;
	color: $c-white;

	.hero__content {
		grid-column: 1 / -1;

		@media #{$mq-l} {
			grid-column: 1 / 6;
		}
	}
}
