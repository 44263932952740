* {
  box-sizing: border-box;
}

html,
body,
main,
#root {
  height: 100%;
}

body {
	margin: 0;
	color: $c-mine;
}

ol,
ul {
	margin: 0 0 0 $gutter-s;
	padding: 0;

	li {
		margin: 0 0 $gutter-s 0;

		&:last-child {
			margin: 0;
		}
	}
}

.wrap {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
	grid-row-gap: $gutter-s;
  margin: 0 auto;
  max-width: 1366px;
  padding: 0 $gutter-s;

  @media #{$mq-m} {
    padding: 0 $gutter-m;
  }

	@media #{$mq-l} {
    grid-gap: $gutter-m;
  }

  @media #{$mq-max} {
    padding: 0;
  }
}

.section {
  padding: $gutter-s 0;

  @media #{$mq-m} {
    padding: $gutter-m 0;
  }

  @media #{$mq-l} {
    padding: $gutter-l 0;
  }

	&.section--short {
		@media #{$mq-l} {
	    padding: $gutter-m 0;
	  }
	}

	&.section--bottom {
		padding-top: 0;
	}

	&.section--top {
		padding-bottom: 0;
	}

  &.section--teal {
    color: $c-white;

    h1,.h1,
    h2,.h2,
    h3,.h3,
    h4,.h4,
    h5,.h4,
    h6,.h6 {
      color: $c-white;
    }
  }
}

.tooltip {
  position: relative;
  cursor: pointer;

  &:after {
    content: attr(title);
    transition: all 200ms;
    transform: translate(-50%, calc(-100% - 10px));
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba($c-black, 0.2);
    background-color: $c-white;
    padding: 10px;
    position: absolute;
    left: 50%;
    top: 0;
    color: $c-black;
    pointer-events: none;
		text-transform: capitalize;
		font-size: 1.2rem;
		line-height: 1.2rem;
		white-space: nowrap;
		z-index: 100;
    opacity: 0;
  }

  &:hover {

    &:after {
      pointer-events: all;
      opacity: 1;
    }
  }
}

.hidden {
	@include hidden();
}
