@import '../../../sass/global.scss';

.medialist {
	padding: $gutter-s;

	@media #{$mq-m} {
		padding: $gutter-m;
	}

	.medialist__header {
		margin: 0 0 $gutter-s 0;
		display: flex;
		flex-direction: column;

		@media #{$mq-m} {
			flex-direction: row;
			padding: 0;
			margin: 0 0 $gutter-m 0;
			justify-content: space-between;
		}

		span {
			font-weight: 700;
		}

		.header__title {
			margin: 0;
		}

		.header__controls {
			display: grid;
			grid-template-columns: 1fr;
			grid-gap: $gutter-s;
			margin: $gutter-s 0 0 0;
			padding: 0;

			@media #{$mq-m} {
				display: flex;
				align-items: center;
				grid-gap: 0;
				margin: 0;
			}

			.controls__li {
				list-style: none;
				margin: 0;

				@media #{$mq-m} {
					margin: 0 $gutter-s 0 0;
				}

				&:last-child {
					margin: 0;
				}

				&.controls__li--invalid {
					background-color: $c-pebble;
					display: flex;
					border-radius: 6px;
					padding: 15px;

					label {
						margin: 0 0 0 $gutter-xs;
						font-weight: 600;
						color: #444;
					}
				}
			}
		}
	}

	.medialist__list {
		grid-column: 1 / -1;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: $gutter-s;
		margin: 0;

		@media #{$mq-m} {
			grid-template-columns: repeat(4, 1fr);
		}

		@media #{$mq-l} {
			grid-template-columns: repeat(6, 1fr);
		}

		.list__item {
			list-style: none;
			margin: 0;

			.item__btn {
				transition: all 200ms;
				background-color: transparent;
				border: none;
				text-align: left;
				padding: 0;
				cursor: pointer;

				&:hover {
					transform: scale(1.05);

					.item__cover {
						box-shadow: 0px 0px 20px rgba($c-black, 0.1);
					}
				}
			}

			.item__cover {
				background-color: $c-pebble;
				border-radius: 10px;
				width: 100%;
				height: auto;
			}

			.item__title {
				margin: $gutter-s-half 0 0 0;
				text-transform: capitalize;
			}

			&:not([data-file-type="mp4"]) {
				filter: grayscale(100%);
				opacity: 0.2;
			}
		}
	}
}
