@import '../../../sass/global.scss';

.input {

	&.input--file {
		display: flex;
		width: 100%;

		.input__file {
			display: none;
		}

		.input__text {
			border-radius: 6px 0 0 6px;
			flex: 0 0 auto;
			cursor: pointer;
		}

		.input__btn {
			background-color: $c-red-dark;
			border: none;
			border-radius: 0 6px 6px 0px;
			padding: $gutter-s-half;
			font-weight: 700;
			font-size: 1.4rem;
			line-height: 1.4rem;
			color: $c-white;
			cursor: pointer;
		}
	}
}
