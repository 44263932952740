@import '../../../sass/global.scss';

.tips {
	background-color: $c-red;
	position: relative;
	color: $c-white;

	.tips__header {
		position: relative;
		display: inline-block;

		@media #{$mq-l} {
			transform: translate(0, calc(-100% - #{$gutter-m}));
			position: absolute;
			top: 0;
			left: 30%;
		}

		svg {
			display: none;

			@media #{$mq-l} {
				transform: translate(calc(100% + #{$gutter-xs}), 15px);
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				height: 40px;
			}
		}

		path {
			fill: $c-white;
		}
	}

	.tips__content {
		grid-column: 1 / -1;
	}

	.tips__list {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: $gutter-s;
		margin: 0;
		padding: 0;

		@media #{$mq-m} {
			grid-template-columns: 1fr 1fr 1fr;
		}

		@media #{$mq-l} {
			grid-gap: $gutter-m;
		}

		.list__item {
			background-color: rgba($c-black, 0.1);
			border-radius: $gutter-xs;
			padding: $gutter-s;
			list-style: none;
			font-size: 1.4rem;
			line-height: 2.4rem;
			margin: 0;

			@media #{$mq-m} {
				border-radius: $gutter-s;
				height: 100%;
			}

			@media #{$mq-l} {
				padding: $gutter-m;
			}

			.item__header {
				display: flex;
				align-items: flex-start;
			}

			.item__num {
				border: 2px $c-yellow solid;
				border-radius: 12px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 $gutter-xs 0 0;
				width: 24px;
				height: 24px;
				font-weight: 700;
				color: $c-yellow;
			}
		}
	}
}
