@import '../../../sass/global.scss';

.select {
	position: relative;

	.select__icon {
		transform: translateY(-50%);
		display: flex;
		position: absolute;
		top: 50%;
		left: $gutter-s-half;
		width: 20px;

		svg {
			width: 100%;
			max-height: 20px;
		}

		path {
			transition: all 200ms;
			fill: $c-gravel;
		}
	}

	select {

		&:focus {

			~ .select__icon {
				path {
					fill: $c-black;
				}
			}
		}
	}

	&.select--icon {

		select {
			padding-left: 40px !important;
		}
	}

	&.select--pebble {

		select {
			background-color: $c-pebble;
			box-shadow: none;
		}
	}
}
