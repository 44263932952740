@import '../../../sass/global.scss';

.btn {
	transition: all 200ms;
	background-color: $c-red-dark;
	border-radius: 40px;
	border: 2px transparent solid;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px $gutter-m;
	font-weight: 700;
	font-size: 1.4rem;
	line-height: 1.4rem;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	width: 100%;
	color: $c-white;
	cursor: pointer;

	@media #{$mq-m} {
		width: auto;
	}

	.btn__icon {
		margin: 0 $gutter-s-half 0 0;
		max-height: 15px;
		width: 15px;

		svg {
			width: 100%;
		}

		path {
			transition: all 200ms;
			fill: $c-white;
		}
	}

	&:hover,
	&.btn--active {
		background-color: $c-red-dark;
	}

	&.btn--small {
		padding: $gutter-s-half;
	}

	&.btn--yellow {
		background-color: $c-yellow;
		color: rgba($c-black, 0.5);
	}

	&.btn--icon {

		&.btn--mobile {

			.btn__icon {
				margin: 0;

				@media #{$mq-xl} {
					margin: 0 5px 0 0;
				}
			}

			.btn__text {
				display: none;

				@media #{$mq-xl} {
					display: inline-flex;
				}
			}
		}
	}

	&.btn--border {
		background-color: transparent;
		border-color: $c-red-dark;
		color: $c-red-dark;

		&.btn--black {
			border-color: $c-black;
			color: $c-black;

			&:hover,
			&.btn--active {
				background-color: $c-black;
				border-color: $c-black;
				color: $c-white;
			}
		}

		&.btn--white {
			border-color: $c-white;
			color: $c-white;

			&:hover,
			&.btn--active {
				background-color: $c-red-dark;
				border-color: $c-red-dark;
				color: $c-white;
			}
		}
	}

	&.btn--chip {
		background-color: transparent;
		border: 2px $c-white solid;
		padding: 5px $gutter-s-half;
		font-size: 1rem;
		line-height: 1rem;
		color: $c-white;

		&:hover {
			background-color: $c-white;
			color: $c-black;
		}
	}

	&.btn--shadow {
		box-shadow: 0 0 40px rgba($c-black, 0.9);
	}

	&.btn--transparent,
	&.btn--circle {
		background-color: transparent;
		padding: 0;
	}

	&.btn--transparent {
		text-transform: none;
		letter-spacing: 0px;
		color: $c-white;

		&:hover {
			border-color: $c-white;
		}

		&.btn--active {
			background-color: $c-red-dark;
			border-color: $c-red-dark;
		}

		&.btn--red {
			color: $c-red;
		}
	}

	&.btn--circle {
		border: none;
		width: auto;

		.btn__icon {
			transition: all 200ms;
			background-color: $c-alabaster;
			border-radius: 14px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0;
			width: 28px;
			height: 28px;
			max-height: none;

			svg {
				width: $gutter-s;
				max-width: 10px;
				max-height: 10px;
			}

			path {
				fill: $c-black;
			}
		}

		&:hover {

			.btn__icon {
				background-color: $c-red;
			}
		}

		&.btn--big {

			.btn__icon {
				border-radius: 20px;
				width: 40px;
				height: 40px;

				svg {
					max-width: 14px;
					max-height: 14px;
				}
			}

			&:hover {

				.btn__icon {
					background-color: $c-white;

					path {
						fill: $c-red;
					}
				}
			}
		}

		&.btn--border {

			.btn__icon {
				background-color: transparent;
				border: 2px $c-white solid;

				path {
					fill: $c-white;
				}
			}
		}
	}

	&.btn--play {
		background-color: $c-red;
		border-radius: 33px;
		padding: 0;
		width: 66px;
		height: 66px;
		flex: 0 0 auto;
		max-width: none;

		.btn__icon {
			margin: 0 0 0 4px;
			width: 20px;
			max-width: none;
			max-height: none;
			height: auto;

			path {
				fill: $c-white;
			}

			&.btn__icon--pause {
				display: none;
			}
		}

		&:hover {
			transform: scale(1.05);
			background-color: $c-red-dark;
			box-shadow: 0px 0px 40px $c-black;

			.btn__icon {
				path {
					fill: $c-white;
				}
			}
		}

		&:disabled {
			background-color: $c-rock;
			pointer-events: none;
			cursor: default;
		}

		&.btn--active {

			.btn__icon {
				margin: 0;

				&.btn__icon--pause {
					display: flex;
				}

				&.btn__icon--play {
					display: none;
				}
			}
		}
	}
}
