@import '../../../sass/global.scss';

.input {
	position: relative;
	display: flex;

	.input__icon {
		transform: translateY(-50%);
		display: flex;
		position: absolute;
		top: 50%;
		left: $gutter-s-half;
		width: 20px;

		svg {
			width: 100%;
		}

		path {
			transition: all 200ms;
			fill: $c-gravel;
		}
	}

	input {

		&:focus {

			~ .input__icon {
				path {
					fill: $c-black;
				}
			}
		}
	}

	&.input--icon {

		input {
			padding-left: 40px !important;
		}
	}
}
