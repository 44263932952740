@import '../../../sass/global.scss';

.hero {
	background-color: $c-red;
	min-height: calc(100vh - #{$header-height-l});
	display: flex;
	align-items: center;
	color: $c-white;

	.hero__content {
		grid-column: 1 / -1;

		@media #{$mq-m} {
			grid-column: 1 / 8;
		}

		@media #{$mq-l} {
			grid-column: 1 / 6;
		}
	}

	.hero__image {
		grid-column: 1 / -1;
		display: block;
		border-radius: 4px;
		width: 100%;
		overflow: hidden;

		@media #{$mq-l} {
			grid-column: 6 / -1;
		}
	}

	.hero__list {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: $gutter-xs;
		margin: 0;
		padding: 0;

		li {
			display: flex;
			align-items: center;
			list-style: none;
			margin: 0;
			font-weight: 600;
		}

		svg {
			margin: 0 $gutter-xs 0 0;
			width: 20px;
		}

		path {
			fill: $c-yellow;
		}
	}

	&.hero--alt {

		.hero__content {

			@media #{$mq-l} {
				grid-column: 8 / -1;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}

		.hero__image {

			@media #{$mq-l} {
				grid-column: 1 / 7;
				grid-row: 1;
			}
		}
	}
}
